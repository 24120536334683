@import '../../styles/dimensions';

/* Card */
$card-height-desktop: 480px;
$card-width-desktop: 435px;

$card-height-mobile: 408px;
$card-width-mobile: 315px;

/* for masonry layout */
$masonry-gap: space(6);
