@import '../../styles/breakpoints';
@import '../../styles/dimensions';
@import './dimensions';

.card {
    background-color: var(--step-white);
    border-radius: $card-border-radius;
    flex: 1 0 auto;

    height: 400px;
    hyphens: auto;
    overflow: hidden;

    @include screen-min-width('md') {
        height: 472px;
    }
}

.scroll-card {
    scroll-snap-align: center;
    width: $card-width-desktop;
    @include screen-max-width('md') {
        width: $card-width-mobile;
    }
}

.card-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: space(4);

    // targets elements within Markdown component
    h6 {
        hyphens: none;
        margin: 0;
        word-break: normal;
    }
}

.card-name {
    margin-top: space(7);

    @include screen-max-width('md') {
        margin-top: space(5);
    }
}

.masonry-card .card-image {
    @include screen-max-width('md') {
        object-fit: cover;
    }
}

.card-bottom {
    justify-self: flex-end;
}

// Card colors
.card-coal-mine-canary {
    background-color: var(--step-coal-mine-canary);
}

.card-future-fuscia {
    background-color: var(--step-future-fuscia);
}

.card-cyanora {
    background-color: var(--step-cyanora);
}

.card-black-v2 {
    background-color: var(--step-black-v2);
}

.star-container {
    margin-bottom: space(2);
}
