@import '../../styles/breakpoints';
@import '../../styles/dimensions';
@import '../../styles/grid-config';
@import './dimensions';

.container {
    margin: space(25) 0;
    @include screen-max-width('md') {
        margin: space(10) 0;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.scroll-row {
    column-gap: $masonry-gap;
    display: flex;
    flex-direction: row;
    -ms-overflow-style: none; /* IE and Edge */
    overflow-x: scroll;
    padding: 0 margin('md');
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    @include screen-max-width('md') {
        padding: 0 margin('_');
        scroll-snap-type: x mandatory;
    }
}

.reivew-disclaimer {
    margin-top: 24px;
    padding: 0 margin('_');

    @include screen-min-width('md') {
        margin-top: 30px;
        padding: 0 margin('md');
    }
}
